import React from "react"
import "../components/layout.css"
import { useStateIfMounted } from "use-state-if-mounted"
import styled from "styled-components"
import { Link } from "gatsby"
import NavigationBar from "../components/HeaderComponents/NavigationBar"
import { MainPageJson, PathJson } from "../components/location"
import { Container, Row, Button, Col } from "react-bootstrap"
import singLanding1 from "../images/Singapore-Landing-1.png"
import singRes1 from "../images/Singapore-Res-1.png"
import singLanding2 from "../images/Singapore-Landing-2.png"
import singRes2 from "../images/Singapore-Res-2.png"
import fbLogo from "../images/socmed-fb-new.png"
import igLogo from "../images/socmed-ig-new.png"
import linkedInLogo from "../images/socmed-linkedin-new.png"
import ytLogo from "../images/socmed-yt-new.png"

function TncSingapuraLanding() {
  const socmedDatas = [
    {
      name: "Instagram",
      link: "https://www.instagram.com/transfez.id/",
      logo: igLogo,
    },
    {
      name: "Facebook",
      link: "https://web.facebook.com/Transfez.id/",
      logo: fbLogo,
    },
    {
      name: "Youtube",
      link: "https://www.youtube.com/channel/UCetpYhmtJh4JZj_bwaVo5vg",
      logo: ytLogo,
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/transfez/",
      logo: linkedInLogo,
    },
  ]

  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const [statusNav, setStatusNav] = useStateIfMounted(false)

  const statusNavbar = status => {
    setStatusNav(status)
  }

  const backWording = '< Kembali'
  return (
    <>
      <NavigationBar
        props={MainPageContent.Header}
        propsLink={PathContent}
        parentCallback={statusNavbar}
        disableNav={true}
      />
      <Container
        className="mt-md-5"
        style={statusNav ? { paddingTop: "80px" } : null}
      >
        <ResponsiveRow>
          <Col md={6}>
            <TitleTnc>Syarat dan Ketentuan</TitleTnc>
            <PointTnc>
              Berikut adalah syarat dan ketentuan mendapatkan saldo e-wallet dan
              voucher untuk aplikasi Transfez regional Singapura dengan total
              nilai 25 SGD:
            </PointTnc>
            <ol
              type="1"
              style={{
                paddingInlineStart: "10px",
              }}
            >
              <ContentLi>Pengguna berdomisili di Singapura.</ContentLi>
              <ContentLi>
                Pengguna harus melakukan pendaftaran dan lolos verifikasi data
                diri melalui aplikasi yang bisa di download saat sudah rilis.
              </ContentLi>
              <ContentLi>
                Link download akan dikirim melalui email beserta cara
                penggunaannya.
              </ContentLi>
              <ContentLi>
                Hadiah diberikan tanpa diundi kepada 300 orang pertama yang
                telah berhasil melakukan verifikasi data diri di akun aplikasi
                Transfez regional Singapura.
              </ContentLi>
              <ContentLi mb="5px">
                Hadiah dengan total nilai 25 SGD yang didapat berupa:
              </ContentLi>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingInlineStart: "20px",
                }}
              >
                <ContentLi mb="5px">
                  Saldo e-wallet di aplikasi Transfez sebesar 10 SGD
                </ContentLi>
                <ContentLi>
                  Tiga voucher untuk transfer gratis dari Singapura ke Indonesia
                  dengan total nilai 15 SGD
                </ContentLi>
              </ul>
              <ContentLi>
                Saldo dan voucher akan masuk secara otomatis di akun pemenang
                dalam waktu maksimal 1 x 24 jam sejak akun telah terverifikasi.
              </ContentLi>
              <ContentLi>
                Keputusan Transfez terkait penentuan pemenang bersifat mutlak
                dan tidak dapat diganggu gugat.
              </ContentLi>
              <ContentLi>
                Transfez tidak memiliki kewajiban untuk melakukan klarifikasi
                kepada pihak manapun terkait proses dan keputusan penentuan
                pemenang.
              </ContentLi>
            </ol>
            <PointTnc>
              Untuk pertanyaan lebih lanjut, silakan hubungi kami melalui email
              di{" "}
              <Mail href="mailto:hello@transfez.com">hello@transfez.com</Mail>
            </PointTnc>

            <Link to="/transfezsingapura">
              <ButtonSend>{backWording}</ButtonSend>
            </Link>
            <SocmedDiv>
              {socmedDatas.map(data => {
                const { link, logo, name } = data
                return (
                  <a href={link} target="_blank">
                    <LogoSocmed src={logo} mr=".5rem" alt={name} />
                  </a>
                )
              })}
            </SocmedDiv>
          </Col>
          <Col md={1} />
          <Col md={5}>
            <ImageDiv1>
              <ImageSin src={singLanding1} alt="singapore"/>
              <ImageSinRes src={singRes1} alt="singapore"/>
            </ImageDiv1>
            {/* <ImageDiv2>
              <ImageSin src={singLanding2} alt="singapore"/>
              <ImageSinRes src={singRes2} alt="singapore"/>
            </ImageDiv2> */}
          </Col>
        </ResponsiveRow>
      </Container>
    </>
  )
}

export default TncSingapuraLanding

const TitleTnc = styled.h5`
  font-size: 20px;
  font-family: "GothamBold";
  color: #48a9b5;
  margin-bottom: 20px;
`

const PointTnc = styled.p`
  font-family: "GothamBook";
  text-align: justify;
  font-size: 14px;
  margin-bottom: 20px;
`

const ContentLi = styled.li`
  font-size: 14px;
  font-family: "GothamBook";
  margin-bottom: ${props => props.mb || "5px"};
  text-align: justify;
  padding-left: 5px;
`

const ResponsiveRow = styled(Row)`
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

const ImageDiv1 = styled.div`
  width: 110%;
  @media (max-width: 576px) {
    width: 100%;
  }
`
const ImageDiv2 = styled.div`
  width: 120%;
  @media (max-width: 576px) {
    width: 100%;
  }
`

const ImageSin = styled.img`
  @media (max-width: 576px) {
    display: none;
  }
`

const ImageSinRes = styled.img`
  @media (min-width: 576px) {
    display: none;
  }
`
const SocmedDiv = styled.div`
  margin-top: 10%;
  margin-bottom: 5%;
  @media (max-width: 576px) {
    text-align: center;
    margin-top: 7%;
  }
`

const LogoSocmed = styled.img`
  margin-left: ${props => props.ml || ".3rem"};
  margin-right: ${props => props.mr || "1rem"};
  width: 40px;
  height: 40px;
  alt: ${props => props.alt};
`

const Mail = styled.a`
  color: #48a9b5;
  :hover {
    color: #48a9b5;
  }
`

const ButtonSend = styled(Button)`
  font-family: "GothamMedium";
  background-color: #ffd27c;
  border-color: #ffd27c;
  color: #196e97;
  font-size: 14px;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  @media (min-width: 768px) {
    margin-right: 1rem !important;
  }
  @media (min-width: 768px) {
    margin-left: 0 !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 0 !important;
  }
  :hover {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffd27c;
    filter: brightness(90%);
  }
`
